import React, { useEffect, useState, useRef } from 'react'

import api from '../services/api'
import VideoJS from './VideoJS' // point to where the functional component is stored
import 'video.js/dist/video-js.css'
import { useInView } from 'react-intersection-observer'
import {
    Text
  } from '@chakra-ui/react'

function VideoPlayer(props) {
  const { id } = props

  const [videoJsOptions, setVideoJsOptions] = useState(false)
  const [signedUrl, setSignedUrl] = useState('')
  const [type, setType] = useState('')
  const [currentTime, setCurrentTime] = useState(0)
  const [update, setUpdate] = useState(0)

  useEffect(async () => {
    const response = await api.get(`/api/v1/video/${id}`)
    // setSignedUrl(String(response.data.signedUrl) + "")
    // setType(String(response.data.type))
    setVideoJsOptions({
      // lookup the options in the docs for more options
      autoplay: false,
      controls: true,
      aspectRatio: '16:9',
      fluid: true,
      sources: [
        {
          src: response.data.signedUrl + '',
          type: "video/mp4",
        },
      ],
    })
  }, [])

  useEffect(async () => {})
  const playerRef = useRef(null)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.65,
  })
  useEffect(() => {
    if (playerRef.current) {
      if (inView) {
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }, [inView])


  const handlePlayerReady = (player) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting')
    })
    player.on('pause', () => {
       let currentTime = playerRef.current.currentTime()
       setCurrentTime(currentTime)
    })

    player.on('dispose', () => {
      console.log('player will dispose')
    })
  }

  // useEffect(async () => {
  //   changePlayerOptions()
  //   setUpdate(update + 1)
  // }, [signedUrl])
  // const changePlayerOptions = () => {
  //   // you can update the player through the Video.js player instance
  //   if (!playerRef.current) {
  //     return
  //   }
  //   // [update player through instance's api]
  //   playerRef.current.src([{ src: signedUrl, type: type }])
  // }

  return (
    videoJsOptions && (
      <div ref={ref}>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        <Text>{currentTime}</Text>
      </div>
    )
  )
}
export default VideoPlayer
