import axios from 'axios'
import { API_URL } from '../constants'
import { getToken } from '../utils/auth'
const api = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 10 * 60,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${getToken()}`
  }
})


export default api
