import React, { Fragment, useEffect, useState, useRef } from 'react'
import api from '../services/api'
import {
  Button,
  Container,
  Flex,
  Box,
  Center,
  Input,
  Alert,
  AlertIcon,
  Spinner,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

import { isAuthenticated } from '../utils/auth'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const ContainerDropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 240px;
  vertical-align: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

const TextDropzone = styled.div`
  margin: auto;
`

function Upload() {
  useEffect(async () => {
    const isAuth = await isAuthenticated()
    if (!isAuth) {
      window.location.href = '/login'
    }
  }, [])

  const [videoName, setVideoName] = useState('')
  const [videoFile, setVideoFile] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [noFiles, setNoFiles] = useState(false)
  const [errorPath, setErrorPath] = useState(false)

  const params = useParams()

  const {
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'video/*', multiple: false })

  async function submitVideo() {
    try {
      if (videoFile) {
        setLoading(true)
        const checkFile = await api.post('/api/v1/file/checkpath/', {
          filename: videoName,
          parent_id: params.parent_id,
        })
        if (!checkFile.data.error) {
          var videoUploadForm = new FormData()
          videoUploadForm.append('file', videoFile)
          videoUploadForm.append('name', videoName)

          const response = await api.post(
            '/api/v1/video/upload',
            videoUploadForm,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )

          const { id, name, thumbnail } = response.data

          if (!response.data.error) {
            await api.post(`/api/v1/file`, {
              filename: videoName && videoName ? videoName : name,
              parent_folder_id: params.parent_id ? params.parent_id : 0,
              video_id: id,
              thumbnail: thumbnail ? thumbnail : null,
            })
            // if (videoName && videoName.length > 0) {
            //   var videoRenameFrom = new FormData()
            //   videoRenameFrom.append('name', videoName)
            //   await api.put(
            //     `/api/v1/video/rename/${id}`,
            //     videoRenameFrom,
            //   )
            // }
            setError(false)
            setNoFiles(false)
            setSuccess(true)
            setErrorPath(false)
          } else {
            throw 'Error'
          }
        } else {
          setError(false)
          setNoFiles(false)
          setSuccess(false)
          setErrorPath(true)
        }
      } else {
        setError(false)
        setNoFiles(true)
        setSuccess(false)
        setErrorPath(false)
      }
    } catch (err) {
      console.log(err)
      setError(true)
      setNoFiles(false)
      setSuccess(false)
      setErrorPath(false)
    }
    setLoading(false)
  }

  return (
    <Container maxW="container.xl">
      <Flex>
        <Box p="4" mr="15" mt="15">
          <Link to={'/dashboard'}>
            <Button size="md" leftIcon={<ArrowBackIcon />} colorScheme="teal">
              Voltar
            </Button>
          </Link>
        </Box>
      </Flex>
      <Input
        mb="4"
        maxLength={50}
        placeholder="Nome do vídeo"
        onChange={(e) => setVideoName(e.target.value)}
      />
      <ContainerDropzone
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input
          {...getInputProps()}
          onChange={(e) => setVideoFile(e.target.files[0])}
        />
        <TextDropzone>
          {videoFile
            ? videoFile.name
            : 'Arraste e solte um arquivo, ou clique para selecionar'}
        </TextDropzone>
      </ContainerDropzone>
      {success && (
        <Alert status="success">
          <AlertIcon />
          Upload realizado com sucesso
        </Alert>
      )}
      {error && (
        <Alert status="error">
          <AlertIcon />
          Erro ao realizar upload
        </Alert>
      )}
      {noFiles && (
        <Alert status="error">
          <AlertIcon />
          Selecione um arquivo para realizar o upload
        </Alert>
      )}
      {errorPath && (
        <Alert status="error">
          <AlertIcon />
          Já existe um arquivo com o mesmo nome dentro da pasta!
        </Alert>
      )}
      <Center>
        <Button
          isLoading={isLoading}
          colorScheme="teal"
          size="md"
          mt="15"
          onClick={submitVideo}
        >
          Confirmar
        </Button>
      </Center>
    </Container>
  )
}

export default Upload
