import React, { useState, useEffect } from 'react'
import api from '../services/api'
import { Button, Container, Box, Center, Input, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { isAuthenticated } from '../utils/auth'

function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(async () => {
    if (await isAuthenticated()) window.location.href = '/dashboard'
  }, [])


  async function handleSubmit() {
    try {
      await api.post('/api/v1/signup', {
        email,
        password,
      })
      window.location.href = '/login'
    } catch {}
  }

  return (
    <Container bgColor="teal" maxW="container.xl">
      <Center bgColor="teal" height="100vh">
        <Box
          textAlign="center"
          borderRadius="15px"
          padding="2rem"
          width="30%"
          bg="white"
          boxShadow="dark-lg"
        >
          <Input
            mb="4"
            maxLength={60}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            mb="4"
            maxLength={60}
            placeholder="Senha"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={handleSubmit} mb="4" size="md" colorScheme="teal">
            Cadastrar
          </Button>
          <Link to={'/login'}>
            <Text textDecoration="underline" fontSize="md">
              Logar
            </Text>
          </Link>
        </Box>
      </Center>
    </Container>
  )
}

export default Signup
