
import Cookies from 'universal-cookie';
import api from '../services/api'

const cookies = new Cookies();

export async function isAuthenticated() {

    const token = cookies.get('@VOD:token')
    if(token){
      try{
        const options = {
          headers:{
            Authorization: `Bearer ${cookies.get('@VOD:token')}`
          }
        }
        const user = await api.get('/api/v1/isLogged', options) 
        return user.data.user.user
      }
      catch{
        return false
      }
    }
  }
export function setToken(token) {
    cookies.set('@VOD:token', token, { path: '/' });
}
export function getToken() {
    return cookies.get('@VOD:token')
}
export function Logout(){
    cookies.remove('@VOD:token')
}
