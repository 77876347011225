import React, { Fragment, useEffect, useState, useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
  Input,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

import api from '../services/api'

function NewFolder(props) {
  console.log(props.parent_id)
  const [name, setName] = useState('')

  const [isOpen, setIsOpen] = useState(false)

  async function handleSubmit() {
    try {
      if(!name || name.length == 0){
        return toast.error('Digite um nome para a pasta', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      const response = await api.post(`/api/v1/file`, {
        filename: name,
        parent_folder_id: props.parent_id ? props.parent_id : 0,
      })
      console.log(response)
      setIsOpen(false)
      props.setCurrentId(props.parent_id)
      props.setUpdateFolder(props.updateFolder + 1)
    } catch (err) {
      console.log(err)
      toast.error('Já existe uma pasta com o mesmo nome neste diretório', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  function handleToggle() {
    setIsOpen(!isOpen)
  }
  return (
    <><ToastContainer />
      <IconButton
        onClick={() => setIsOpen(true)}
        icon={<AddIcon />}
        colorScheme="teal"
        variant="outline"
      ></IconButton>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nova Pasta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              maxLength={50}
              placeholder="Nome da pasta"
              size="md"
              onChange={(e) => setName(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={() => setIsOpen(false)}>
              Cancelar
            </Button>
            <Button onClick={handleSubmit}>Salvar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default NewFolder
