import React, { useEffect, useState, useRef, useDisclosure } from 'react'
import { useParams, Link } from 'react-router-dom'

import api from '../services/api'
import 'video.js/dist/video-js.css'
import VideoPlayer from './VideoPlayer'

import {
  Container,
  Button,
  Flex,
  Box,
  Slide,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'

import { isAuthenticated } from '../utils/auth'


function VideoPage() {

  const [isOpen, setIsOpen ] = useState(false)

  useEffect(async () => {
    const isAuth = await isAuthenticated()
    if (!isAuth) {
      window.location.href = '/login'
    }
  }, [])
  
  let params = useParams()
  const [videoData, setVideoData] = useState()
  useEffect(async () => {
    const response = await api.get(`/api/v1/video/${params.id}`)
    setVideoData(response.data)
  }, [])


  return (
    <Container maxW="container.lg">
      <Flex>
      <Box p="4" mr="15" mt="15">
          <Link to={'/dashboard/'}>
            <Button size="md" leftIcon={<ArrowBackIcon />} colorScheme="teal">
              Voltar
            </Button>
          </Link>
        </Box>
        <Spacer></Spacer>
        <Box p="4" mr="15" mt="15" ><Button onClick={() => setIsOpen(!isOpen)}>Show Info</Button></Box>
      </Flex>
      <VideoPlayer id={params.id}/>
      <Slide direction='bottom' in={isOpen} style={{ zIndex: 10 }}>
        <Flex
          p='40px'
          color='white'
          mt='4'
          bg='teal.500'
          rounded='md'
          shadow='md'
        >
          <Text m="2">Name: {videoData && videoData.name} </Text>
          <Text m="2">Size: {videoData && videoData.size}</Text>
          <Text m="2">Duration: {videoData && videoData.duration}</Text>
          <Text m="2">Ext: {videoData && videoData.ext}</Text>
          <Text m="2">Resolution: {videoData && videoData.resolution}</Text>
          <Text m="2">BitRate: {videoData && videoData.bit_rate}</Text>
          <Text m="2">Width: {videoData && videoData.width}</Text>
          <Text m="2">Height: {videoData && videoData.height}</Text>
          <Text m="2">Format Name: {videoData && videoData.format_name}</Text>
          <Text m="2">Type: {videoData && videoData.type}</Text>

        </Flex>
      </Slide>
    </Container>
  )
}
export default VideoPage
