import React, { Fragment, useEffect, useState, useDisclosure } from 'react'
import { useParams } from 'react-router-dom'
import { API_URL } from '../constants'

import api from '../services/api'
import {
  List,
  ListItem,
  Button,
  Container,
  Flex,
  Spacer,
  Box,
  Grid,
  Text,
  Image,
  Input,
  Center,
} from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ArrowUpIcon, TriangleUpIcon, CloseIcon } from '@chakra-ui/icons'
import NewFolder, { newFolderModal } from './newFolderModal'
import { isAuthenticated, Logout } from '../utils/auth'

function ListVideo() {
  const [isAdmin, setIsAdmin] = useState(true)
  useEffect(async () => {
    const isAuth = await isAuthenticated()

    if (!isAuth) {
      window.location.href = '/login'
    }
    if (isAuth.id != -1) {
      setIsAdmin(false)
    }
  }, [])

  const [list, setList] = useState([])
  const [updateFolder, setUpdateFolder] = useState(0)
  const [folderData, setFolderData] = useState({
    fullpath: '/',
    parent_id: null,
  })
  const [currentId, setId] = useState(null)
  let params = useParams()
  if (params.id) {
    if (currentId != params.id) setId(params.id)
  } else {
    if (currentId != 0) setId(0)
  }

  async function handleLogout() {
    await Logout()
    window.location.href = '/login'
  }

  useEffect(async () => {
    if (params.id) {
      const response = await api.get(`/api/v1/file?folder=${params.id}`)
      setFolderData(response.data.folderData)
      setList(response.data.files)
    } else {
      const response = await api.get(`/api/v1/file`)
      setFolderData(response.data.folderData)
      setList(response.data.files)
    }
  }, [currentId, updateFolder])


  return (
    <Container maxW="container.xl">
      <Flex>
        <Box p="4" mr="0" mt="15">
          <Link
            to={
              folderData.parent_id ? `/dashboard/${folderData.parent_id}` : '#'
            }
          >
            <IconButton
              icon={<ArrowUpIcon />}
              colorScheme="teal"
              variant="outline"
            ></IconButton>
          </Link>
        </Box>
        <Box p="4" mr="3" mt="15">
          <Link to={'/dashboard'}>
            <IconButton
              icon={<TriangleUpIcon />}
              colorScheme="teal"
              variant="outline"
            ></IconButton>
          </Link>
        </Box>
        <Input size="lg" mt="7" _readOnly="true" value={folderData.fullpath} />
        <Spacer />
        {!isAdmin && (
          <>
            <Box p="4" mr="3" mt="15">
              <NewFolder
                parent_id={currentId}
                setCurrentId={setId}
                setUpdateFolder={setUpdateFolder}
                updateFolder={updateFolder}
              />
            </Box>
            <Box p="4" mr="5" mt="15">
              <Link to={`/dashboard/upload/${currentId}`}>
                <Button
                  rightIcon={<ArrowUpIcon />}
                  colorScheme="teal"
                  variant="outline"
                >
                  Subir video
                </Button>
              </Link>
            </Box>
          </>
        )}
        <Box p="4" mr="15" mt="15">
          <Button
            onClick={handleLogout}
            rightIcon={<CloseIcon />}
            colorScheme="teal"
          >
            Sair
          </Button>
        </Box>
      </Flex>
      <List>
        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
          {list.map((file) =>
            file.is_folder ? (
              <ListItem key={file.id}>
                <Link to={`/dashboard/${file.id}`}>
                  <Box w="100%" h="40vh" boxShadow="2xl" rounded="md">
                    <Center w="100%" h="85%">
                      {' '}
                      <Image p="10px" src={'/folder.png'} />
                    </Center>
                    <Text m="8px">{file.file_name}</Text>
                  </Box>
                </Link>
              </ListItem>
            ) : (
              <ListItem key={file.id}>
                <Link to={`/dashboard/video/${file.video_id}`}>
                  <Box w="100%" h="40vh" boxShadow="2xl" rounded="md">
                    <Center
                      w="100%"
                      h="85%"
                      backgroundImage={`url(${API_URL}/uploads/${file.thumbnail})`}
                      backgroundSize={'cover'}
                      backgroundPosition={'center'}
                    >
                      <Image w="30%" src={'/video.png'} />
                    </Center>
                    <Text m="8px">{file.file_name}</Text>
                  </Box>
                </Link>
              </ListItem>
            ),
          )}
        </Grid>
      </List>
    </Container>
  )
}

export default ListVideo
