import React, { Fragment, useEffect, useState, useRef } from 'react'
import api from '../services/api'
import {
  List,
  ListItem,
  Container,
  Box,
  Grid,
  Text,
  Image,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import VideoPlayer from './VideoPlayer'
import { isAuthenticated } from '../utils/auth'

function ListVideo() {
  const [list, setList] = useState([])

  useEffect(async () => {
    const response = await api.get('/api/v1/video')
    console.log(response.data)
    setList(response.data)
  }, [])

  useEffect(async () => {
    const isAuth = await isAuthenticated()
    if (!isAuth) {
      window.location.href = '/login'
    }
  }, [])

  return (
    <Container maxW="container.xl">
      <List>
        <Grid templateColumns="repeat(1, 1fr)" gap={6}>
          {list.map((video) => (
            <ListItem key={video.id}>
              <VideoPlayer id={video.id} />
            </ListItem>
          ))}
        </Grid>
      </List>
    </Container>
  )
}

export default ListVideo
