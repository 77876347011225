import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import VideoPage from './components/VideoPage'
import ListVideos from './components/listVideo'
import Upload from './components/upload'
import Timeline from './components/timeline'
import Login from './components/login'
import Signup from './components/signup'

export default function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/dashboard/" element={<ListVideos />} />
        <Route path="/dashboard/:id" element={<ListVideos />} />
        <Route path="/dashboard/video/:id" element={<VideoPage />} />
        <Route path="/dashboard/upload/:parent_id" element={ <Upload/>}/>
      </Routes>
    </Router>
  );
}